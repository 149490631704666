"use strict";

import "regulus-oscar";

$(function () {
    $('.slider').each(function (_, element) {
        const slider = $(element);
        const sliderContainer = slider.find('.slider-container');
        const sliderItems = sliderContainer.children();
        const scrollAmount = sliderItems.outerWidth(true); // Get the width of each item including margin
        // Clone all items and append them to the container for the infinite effect
        sliderItems.clone().appendTo(sliderContainer);
        let isScrolling = false;
        function scrollNext() {
            if (!isScrolling) {
                isScrolling = true;
                // Scroll the slider by the width of one item
                sliderContainer.animate({
                    scrollLeft: '+=' + scrollAmount
                }, 800, 'swing', function () {
                    isScrolling = false;
                    // If scrolled past original items, reset to start
                    if (sliderContainer.scrollLeft() >= sliderItems.length * scrollAmount) {
                        sliderContainer.scrollLeft(0);
                    }
                });
            }
        }
        slider.find('.slider-previous').click(function () {
            if (!isScrolling) {
                isScrolling = true;
                sliderContainer.animate({
                    scrollLeft: '-=' + scrollAmount
                }, 800, 'swing', function () {
                    isScrolling = false;
                    // If scrolled past original items, reset to end
                    if (sliderContainer.scrollLeft() <= 0) {
                        sliderContainer.scrollLeft(sliderItems.length * scrollAmount);
                    }
                });
            }
        });
        slider.find('.next').click(function () {
            scrollNext();
        });
        // Automatically scroll next every 2 seconds for infinite effect
        setInterval(scrollNext, 2000);
    });
});